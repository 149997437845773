import m from '@otovo/shared/components/Navigation/messages';
import { NavbarLink } from '@otovo/shared/components/Navigation/types';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { getBlogLink } from '@otovo/shared/lib/getBlogLink';
import useProfile from '../components/LoginPage/hooks/useProfile';

export function useNavbarLinks(): Array<NavbarLink> {
  const config = useGlobalConfig();
  const { user } = useProfile({
    redirectToLogin: false,
  });

  const { market } = config.BU_CONFIG;

  const links = [] as Array<NavbarLink>;

  if (market === 'fr') {
    links.push({ to: `${config.STOREFRONT_URL}/`, intlKey: m.storefront });
  }

  links.push({ to: `${config.STOREFRONT_URL}/about/`, intlKey: m.aboutUs });

  const blogLink = getBlogLink(config);

  if (blogLink) {
    links.push(blogLink);
  }

  if (config.HUBSPOT_KNOWLEDGE_BASE_URL) {
    links.push({
      to: config.HUBSPOT_KNOWLEDGE_BASE_URL,
      intlKey: m.faqTitle,
      nofollow: true,
    });
  }

  if (market === 'fr') {
    links.push(
      {
        to: `${config.STOREFRONT_URL}/a/location-panneau-solaire/`,
        intlKey: m.leasingInstallation,
      },
      {
        to: `${config.STOREFRONT_URL}/a/offre/`,
        intlKey: m.buyNowInstallation,
      },
    );
  }

  links.push(
    { to: '/', intlKey: m.myPage },
    {
      to: '/economy',
      intlKey: m.economyPageName,
    },
    {
      to: '/account',
      intlKey: m.accountPageName,
    },
  );

  if (user) {
    links.push(
      {
        to: '/help-center',
        intlKey: m.helpCenter,
      },
      {
        to: '/logout',
        intlKey: m.logout,
      },
    );
  }

  if (config.urls.referralPageUrl) {
    links.push({
      to: `${config.urls.referralPageUrl}`,
      intlKey: m.otovoReferralProgram,
    });
  }

  return links;
}
